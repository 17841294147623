import { Injectable } from '@angular/core';
import { CompositeFilterDescriptor, FilterDescriptor, State, isCompositeFilterDescriptor, toODataString } from '@progress/kendo-data-query';
import { Params } from '@angular/router';
import { LocalStorage } from '../models/enum/LocalStorage';

@Injectable({
  providedIn: 'root',
})
export class UtilService {
  constructor() {}

  private makeCompositeFilterDescriptorCaseInsensitive(value: CompositeFilterDescriptor): CompositeFilterDescriptor {
    let _filter = value.filters;
    for (const filter of _filter) {
      this.filterRecursive(filter);
    }

    return {
      logic: value.logic,
      filters: _filter,
    };
  }
  /**
   * Permet de creuser récursivement pour que les FilterDescriptor au
   * sein du ou des CompositeFilterDescriptor se font rajouter le case insensitivity
   * @param filter
   */
  private filterRecursive(filter: CompositeFilterDescriptor | FilterDescriptor): void {
    const caseSensitiveOperators: string[] = ['contains', 'eq', 'neq', 'startswith', 'endswith', 'doesnotcontain'];
    // Certain champs de type enum saute et on doit donc les exclures
    let fieldListToAvoid = ['Ctrl_Status', 'Ctrl_ERP_InvoiceAP_Status'];

    if (this.isAnInstanceOfFilterDescriptor(filter)) {
      let field = `${filter.field}`;
      if (caseSensitiveOperators.indexOf(filter.operator.toString()) !== -1) {
        if (field && fieldListToAvoid.indexOf(field) > -1) {
          filter.ignoreCase = false;
        } else {
          filter.ignoreCase = true;
          if (typeof filter.value === 'string') {
            filter.value = filter.value.toLowerCase();
          }
        }
      }
    } else {
      for (const subFilter of filter.filters) {
        this.filterRecursive(subFilter);
      }
    }
  }

  /**
   * Permet de savoir si un objet qui hérite de l'interface FilterDescriptor
   * @param filter
   */
  public isAnInstanceOfFilterDescriptor(_obj: FilterDescriptor | CompositeFilterDescriptor): _obj is FilterDescriptor {
    return 'field' in _obj;
  }

  public getODataFullString(state: State, oDataString: string) {
    // merge oDataString Filter and grid Filter, remove from state
    let newState: State = {
      take: state.take,
      skip: state.skip,
      sort: state.sort,
      group: state.group,
    };

    let filterStr: string = '';

    // S'il y a un ou des filtres d'appliqués alors on les rend case insensitive
    if (state.filter) {
      filterStr = toODataString({ filter: this.makeCompositeFilterDescriptorCaseInsensitive(state.filter) });
    } else {
      filterStr = toODataString({ filter: state.filter });
    }
    // Corrige un edge case où la requête formée pouvait avoir plusieurs filter statement.
    if (filterStr) {
      filterStr = filterStr.replace('$filter=', '');
      filterStr = filterStr.replace(/T00:00:00.000Z/g, '');
      if (oDataString) {
        if (oDataString.indexOf('$filter') != -1) {
          oDataString += ' and ' + filterStr;
        } else {
          oDataString += '&$filter=' + filterStr;
        }
      }
    }
    let data = oDataString + '&' + toODataString(newState);
    return data;
  }

  public makeStateCaseInsensitive(state: State): State {
    let _state = state;
    if (state.filter) {
      _state.filter = this.makeCompositeFilterDescriptorCaseInsensitive(state.filter);
    }

    return state;
  }

  /**
   * Permet de creuser récursivement pour que les FilterDescriptor au
   * sein du ou des CompositeFilterDescriptor se font rajouter leur params au URL de la page
   * @param filter
   */
  public constructURIQueryParamsFromFilter(filter: CompositeFilterDescriptor | FilterDescriptor): string {
    let returnString = '';
    if (this.isAnInstanceOfFilterDescriptor(filter)) {
      let _value = filter.value;
      /*Les raw value des dates provenant des Grid sont trop sales on doit faire du traitement manuel additionel*/
      if (typeof _value === 'object' && !isNaN(_value.getTime())) {
        // c'est une date
        let year = _value.getFullYear();
        let month = _value.getMonth() + 1; // Adding 1 since months are zero-based (0-11)
        let day = _value.getDate();
        _value = `${year}-${month}-${day}`;
      }

      returnString += `;${filter.field}=${encodeURIComponent(_value)}`;
    } else {
      for (const _filter of filter.filters) {
        returnString += this.constructURIQueryParamsFromFilter(_filter);
      }
    }

    return returnString;
  }

  /**
   * Contruit un kendo filter selon les paramètre fournit dans l'URI
   * @param params URI PARAMS
   * @param fieldTypes Colonnes ainsi que leur types
   * @returns Un filtre du kendo grid
   */
  public constructKendoFilterFromQueryParams(params: Params, fieldTypes: FieldType[]): CompositeFilterDescriptor {
    let routeParamNames = Object.keys(params);
    let routeParamValues = Object.values(params);

    let filter: CompositeFilterDescriptor = {
      logic: 'and',
      filters: [],
    };

    for (let i = 0; i < routeParamNames.length; i++) {
      let name = routeParamNames[i];
      let value = routeParamValues[i];
      let _types = fieldTypes.filter((x) => x.name === name);

      if (_types.length === 0) {
        break;
      }

      let myType = _types[0].type;
      let myOperator = myType === 'text' ? 'contains' : 'eq'; // type === 'text' ? 'contains' : 'eq', //si string alors 'contains' sinon bool et number va etre de type 'eq'

      //si le type est number alors il faut transformer la valeur string en number
      switch (myType.toLowerCase()) {
        case 'numeric':
          //par défaut, l'opérateur est eq
          myOperator = 'eq';

          //On regarde si les 2 premiers char sont des lettres
          let regex3 = /^[a-zA-Z]{3}/;
          let regex2 = /^[a-zA-Z]{2}/;
          if (regex3.test(value)) {
            myOperator = value.substring(0, 3);
            value = value.substring(3, 999);
          } else if (regex2.test(value)) {
            myOperator = value.substring(0, 2);
            value = value.substring(2, 999);
          }
          value = +value;
          break;
        case 'boolean':
          value = value.toLowerCase() === 'true' ? true : false;
          break;
        case 'date':
          value = new Date(value);
          break;
        default:
          myOperator = 'contains';
          break;
      }

      let filterToAdd: FilterDescriptor = {
        field: name,
        operator: myOperator,
        value: value,
      };

      filter.filters.push(filterToAdd);
    }

    return filter;
  }

  /** permet de parser les filtres de l'url et va retourner une série de filtrage correspondant pour Kendo [FilterDescriptor]
   * @argument params : les paramètres de l'url (le tableua de params)
   * @returns : un tableau de FilterDescriptor prêt à l'usager pour kendo.
   * ATTENTION,  pour l'isntant on ne gère qu'un niveau (donc pas de or ou and imbriqué)
   */
  public parseParamForKendoAutoFilter(params: any, autoFilterPrefix: string = '$autoFilter_'): FilterDescriptor[] {
    //on prépare un tableau de filtre
    const filters: FilterDescriptor[] = [];
    //pour chaque elemnent du queryparams
    for (const key in params) {
      //si l'element commence par $autoFilter_, on va le traiter
      if (params.hasOwnProperty(key) && key.startsWith(autoFilterPrefix)) {
        //le nom du champ est le nom du champ sans le $autoFilter_
        const field = key.replace(autoFilterPrefix, '');
        //on va splitter la valeur pour obtenir l'opérateur et la valeur du filtre
        const value = params[key];

        const [operator, filterValue] = value.split('|'); //on split sur un pipe.

        //si le filter value est une date de format ISO ou juste yyyy-MM-dd, on va agir en conséquence...car sinon ça va planter [ca string tout croche]
        let myValue = filterValue;
        //si c'est une date:
        if (
          new Date(filterValue).toString() !== 'Invalid Date' &&
          !isNaN(new Date(filterValue).getTime()) &&
          new Date(filterValue) !== null &&
          new Date(filterValue) !== undefined
        ) {
          myValue = new Date(filterValue);
        }

        //on ajoute le filtre au array de filtre
        filters.push({
          field: field,
          operator: operator,
          value: myValue,
        });
      }
    }
    return filters;
  }

  /**
   * Permet de FABRIQUER les queryparams à partir d'un filtre Kendo (this.state.filter).
   * @param filter
   * @returns
   */
  public buildQueryParamsFromFilters(filter: CompositeFilterDescriptor, autoFilterPrefix: string = '$autoFilter_'): any {
    const queryParams: any = {};

    filter.filters.forEach((f: any) => {
      //si l'element est un CompositeFilterDescription, on va devoir le traiter)
      if (isCompositeFilterDescriptor(f)) {
        let myQueryParams = this.buildQueryParamsFromFilters(f);
        Object.assign(queryParams, myQueryParams);
      } else {
        let myFilterDescription = f;
        //si value est une DATE, on va devoir formatter ça correctement
        let myValueFormatted = myFilterDescription.value;
        if (myFilterDescription.value instanceof Date) {
          myValueFormatted = this.formatDateToString(myFilterDescription.value);
          //on va convertir la date en format ISO :
          myValueFormatted = myFilterDescription.value.toISOString();
        }

        //le query param va être : $autoFilter-[field]=[operator]|[value]
        queryParams[`${autoFilterPrefix}${myFilterDescription.field}`] = `${myFilterDescription.operator}|${myValueFormatted}`;
      }
    });
    return queryParams;
  }

  public setRowPerPage(value): void {
    localStorage.setItem(LocalStorage.PAGINATION_ROW_PER_PAGE, value);
  }

  public getRowPerPage(): number {
    let rawValue: string = localStorage.getItem(LocalStorage.PAGINATION_ROW_PER_PAGE);
    let cleanValue: number = rawValue === null || rawValue === undefined ? 25 : isNaN(+rawValue) ? 25 : +rawValue;

    return cleanValue;
  }

  public formatDateToString(_date: Date): string {
    let workingString = `${_date}`;
    workingString = workingString.substring(0, workingString.length - 6);
    workingString = workingString.replace('T', ' ');
    return workingString;
  }
}

export interface FieldType {
  name: string;
  type: string;
}
